// most polyfills are imported by Angular
// https://github.com/angular/angular-cli/blob/master/packages/angular_devkit/build_angular/src/angular-cli-files/models/es5-polyfills.js

// import 'core-js/es';

// import core-js polyfills
// import 'core-js/es/array';
// import 'core-js/es/object';
// import 'core-js/es/set';
// import 'core-js/es/string';

// specific web browser additions
// import 'core-js/web/dom-collections';
// import 'core-js/web/url';

// dev imports
// import 'shim-keyboard-event-key';
// import 'whatwg-fetch';

// always import this after polyfills are loaded
import 'zone.js';
